import React from 'react'
import Header from '../Common/Header'
import ServiceContent from '../page_content/Service_content'
import Footer from '../Common/Footer'

function Service() {
  return (
    <div>
      <title>Service </title>
        <Header/>
        <ServiceContent/>
        <Footer/>
    </div>
  )
}

export default Service