import React, { useState } from 'react'
import axios from "axios"
// contactus
import email from '../../assets/images/email.svg'
import phone from '../../assets/images/phone.svg'
import location from '../../assets/images/location.svg'
import buisness from '../../assets/images/buisness.svg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Contactus() {
  const [form, setFrom] = useState({})
  const Success_notify = () => toast("Enquiry Submitted Successfully");
  const error_notify = () => toast("Please Try Again Later");


  const handleInput = (e) => {
    setFrom({
      ...form,
      [e.target.name]: e.target.value
    })
  }
  let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    axios.post('https://mindcoadmin.v4masters.com/public/index.php/contactus', form)
      .then((response) => {
        if (response.data == "success") {
          Success_notify()
          setTimeout(()=>{
            window.location.reload()
          },3000)
        } else {
          error_notify()
        }
      })
      .catch((error) => {

      })

  }
  return (
    <>
      <section className="section3 section5 bg-white" id="contact-us">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
         />
        <div className="contact-us-section">
          <div className="grid-row grid-row2">
            <div className="left-side grid-coloumn">
              <div className="header-text">
                <h2 className="text-purple">We'd love to hear from you</h2>
                <p className="fw-medium">We are one call away...</p>
                <div className="text-gray">
                  Give your Idea a digital escalation Get started with MindCodeLab today
                </div>
              </div>
              <ul className="main-list">
                <li>
                  <h3 className='text-purple fw-medium'>Office Locations</h3></li>
                <li className="details-list">
                  <div className="d-flex">
                    <img src={location} alt="" />
                    <div>
                      <span className="text-gray">Canada</span> <br />
                      <span className="text-black">
                        1315 Kingston Rd, Toronto, (Canada)

                      </span>
                      <br />
                      <span><a href="tel: +1(343)453-0013" className="text-black" >+1(343)453-0013 </a></span>

                    </div>
                  </div>
                </li>
                <li className="details-list">
                  <div className="d-flex">
                    <img src={location} alt="" />
                    <div>
                      <span className="text-gray">India</span> <br />
                      <span className="text-black">
                        SCO 12-13 , Sec 65, Chandigarh(India)
                      </span>
                      <br />
                      <span><a href="tel:+91 9896570012" className="text-black"> +91 9896570012</a></span>
                    </div>
                  </div>
                </li>
                <li className="details-list">
                  <div className="d-flex">
                    <img
                      src={buisness}
                      height="max-content"
                      alt=""
                    />
                    <div>
                      <span className="text-gray">Business Hours</span> <br />
                      <span className="text-black"> Monday to Saturday </span>
                      <br />
                      <span className="text-black"> 10:00 AM to 07:30 PM</span>
                    </div>
                  </div>
                </li>
                <li>
                  <a href="mailto:sales@mindcondelab.pro" className="d-flex">
                    <img src={email} alt="" />
                    <div>
                      <span className="text-gray">Email Address </span> <br />
                      <span className="text-black">sales@mindcondelab.pro</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="right-side">
              <div className="contact-us-form">
                <h2 className="text-purple">Contact us</h2>
                <div className="form-container">
                  <form className="form-data" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="First Name"
                        id="fname"
                        name="fname"
                        required
                        onChange={handleInput}
                      />
                      <input
                        type="text"
                        id="lname"
                        placeholder="Last Name"
                        name="lname"
                        required
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        placeholder="Email Address"
                        name="email"
                        required
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        id="phone"
                        placeholder="Phone Number"
                        name="phone"
                        required
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        id="message"
                        placeholder="Your Message"
                        name="message"
                        required
                        onChange={handleInput}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <input
                        type="submit"
                        value="Submit"
                        className="btn btn-black text-white"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contactus