import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo.svg";

import { NavLink, useLocation } from "react-router-dom";

function Header() {
  const [toggle, setToggle] = useState(false);
  const handletogle = () => {
    setToggle(!toggle);
  };
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const anchor = document.querySelector(location.hash);
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <>
      <header id="header" className="d-flex">
      <div className="mainlogo">
          <NavLink to={"/"}>
            <img src={logo} alt="logo" />
          </NavLink>
        </div>
        <div className="mainlogo2">
          <NavLink to={"/"}>
            <img src={logo2} alt="logo" />
          </NavLink>
        </div>
        <nav
          className={`navbar ${toggle ? "show-list" : ""} d-flex`}
          id="mainListDiv"
        >
          <ul className="nav-list menulist d-flex">
            <li className="navigation ">
              <NavLink to={"/"}>Home</NavLink>
            </li>
            <li className="dropdown navigation">
              <NavLink to={"/about"} className="dropbtn d-flex">
                About
                <i>
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
              </NavLink>
              <div className="dropdown-content">
                {/* <NavLink to={"/about"} className="first">
                  Overview
                </NavLink> */}
                <NavLink to={"/about#our-mission"} className="first">Our Mission</NavLink>
                <NavLink to={"/about#company-history"} className="last">
                  History
                </NavLink>
              </div>
            </li>
            <li className="dropdown navigation">
              <NavLink to={"/product"} className="dropbtn">
                Products
                <i>
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
              </NavLink>
              <div className="dropdown-content">
                <NavLink to={"/product#Comercio-affordable"} className="first">
                  Comercio
                </NavLink>
                <NavLink to={"/product#Medico"}>Medico</NavLink>
                <NavLink to={"/product#taxi"}>Transportation</NavLink>
                <NavLink
                  to={"/product#education"}
                  onClick={() => scrollToSection("education")}
                >
                  Education
                </NavLink>
                <NavLink
                  to={"/product#Examen"}
                  onClick={() => scrollToSection("Examen")}
                >
                  Examen
                </NavLink>
                <NavLink to={"/product#realestate"} className="last">
                  Real Estate
                </NavLink>
              </div>
            </li>
            <li className="dropdown navigation">
              <NavLink to={"/service"} className="dropbtn">
                Services
                <i>
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
              </NavLink>
              <div className="dropdown-content">
                <NavLink to={"/service"} className="first">
                  Logo And Branding
                </NavLink>
                <NavLink to={"/service"}>UI/UX</NavLink>
                <NavLink to={"/service"}>Web Desiging</NavLink>
                <NavLink to={"/service"}>Android App Development</NavLink>
                <NavLink to={"/service"}>IOS App Development </NavLink>
                <NavLink to={"/service"}>Artificial Intelligence</NavLink>
                <NavLink to={"/service"}>It Support And Cloud Service</NavLink>
                <NavLink to={"/service"} className="last">
                  Digital Marketing
                </NavLink>
              </div>
            </li>
            <li className="dropdown navigation">
              <NavLink to={"/career"} className="dropbtn">
                Career
                <i>
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
              </NavLink>
              <div className="dropdown-content">
                <NavLink to={"/career"} className="first">
                  Apply For Job
                </NavLink>
                <NavLink to={"/internship"} className="last">
                  Apply For Internship
                </NavLink>
              </div>
            </li>
            <li className="dropdown navigation">
              <NavLink to={"/event"} className="dropbtn">
                Events
              </NavLink>
            </li>
          </ul>
          <ul className="nav-list hilighted-nav  contact-ul">
            <li>
              <NavLink to={"/contact"} className="btn btn-black">
                Contact Us
              </NavLink>
            </li>
          </ul>
        </nav>
        <span
          className={`navTrigger ${toggle ? "active" : ""}`}
          onClick={handletogle}
        >
          <i></i>
          <i></i>
          <i></i>
        </span>
      </header>
    </>
  );
}

export default Header;
