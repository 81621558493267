import React from 'react'
import InternContent from '../page_content/Intern_content'
import Header from '../Common/Header'
import Footer from '../Common/Footer'

function Intern() {
  return (
    <div>
      <title>Internship </title>

      <Header />
      <InternContent />
      <Footer />
    </div>
  )
}

export default Intern