import React, { useEffect } from "react";
import Group3 from "../../assets/images/Group 3.png";
import upwork from "../../assets/images/upwork 1.svg";
import facebook from "../../assets/images/facebook.png";
import insta from "../../assets/images/insta.png";
import linkedin from "../../assets/images/linkedin.png";
import { NavLink, useLocation } from "react-router-dom";
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function Footer() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const anchor = document.querySelector(location.hash);
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offsetTop = section.offsetTop;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleNavLinkClick = () => {
    scrollToTop();
  };

  return (
    <footer>
      <div className="container footer-section">
        <div className="footer-grid">
          <div className="company-logo">
            <NavLink to={"/"}>
              <img src={Group3} alt="Logo" />
            </NavLink>
            <div className="largefont">
              With 10 years of Designing, Developing, Marketing and Branding,
              <br />
              MindCodeLab Pvt Ltd has shown phenomenal growth.
            </div>
          </div>
          <div className="right-side largefont">
            <ul className="text-white first-list-section first-item">
              <li className="">
                <NavLink exact to={"/"} onClick={handleNavLinkClick}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to={"/about"} onClick={handleNavLinkClick}>
                  About
                </NavLink>
              </li>
              <li>
                <NavLink to={"/product"} onClick={handleNavLinkClick}>
                  Product
                </NavLink>
              </li>
              <li>
                <NavLink to={"/service"} onClick={handleNavLinkClick}>
                  Services
                </NavLink>
              </li>
            </ul>
            <ul className="text-white first-list-section">
              <li>
                <NavLink to={"/career"} onClick={handleNavLinkClick}>
                  Career
                </NavLink>
              </li>
              <li>
                <NavLink to={"/"} onClick={handleNavLinkClick}>
                  Client
                </NavLink>
              </li>
              <li>
                <NavLink to={"/"} onClick={handleNavLinkClick}>
                  Case study
                </NavLink>
              </li>
              <li>
                <NavLink to={"/contact"} onClick={handleNavLinkClick}>
                  Contact us
                </NavLink>
              </li>
            </ul>
            <div className="second-list-item d-flex">
              <a
                href="https://www.upwork.com/agencies/mindcodelab/"
                className="btn-black border-white text-white"
              >
                <h6>
                  Hire us on
                  <img src={upwork} alt="upwork" style={{ margin: "0 0 -3px 0" }} />
                </h6>
              </a>
              <div>
                <a href="https://www.facebook.com/mindcodelab/" className="">
                  <img src={facebook} alt="facebook-icon" height="30px" width="30px" />
                </a>
                <a href="https://www.instagram.com/mindcodelab/" className="">
                  <img src={insta} alt="instagram-icon" height="30px" width="30px" />
                </a>
                <a
                  href="https://www.linkedin.com/company/mindcodelab/"
                  className=""
                >
                  <img src={linkedin} alt="linkedin-icon" height="30px" width="30px" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line"></div>
      <div className="container copyright">
        <span>Copyright ©2024 MindCode Lab Pvt Ltd | All Rights Reserved.</span>
        <span>Privacy Policy | Terms & Conditions</span>
      </div>
    </footer>
  );
}

export default Footer;
