import React from "react";
// import Ourservice from '../Common/our_service'
import Contactus from "../Common/Contactus";
// our service image
import android2 from "../../assets/images/android2.png";
import vector1 from "../../assets/images/Vector (1).png";
import vector2 from "../../assets/images/Vector (2).png";
import vector3 from "../../assets/images/Vector (3).png";
import vector4 from "../../assets/images/Vector (4).png";
import vector5 from "../../assets/images/Vector (5).png";
import vector6 from "../../assets/images/Vector (6).png";
import vector7 from "../../assets/images/Vector (7).png";
import webdesign2 from "../../assets/images/webdesign2.png";
import digitalmarketing2 from "../../assets/images/digitalmarketing2.png";
import iosapp2 from "../../assets/images/iosapp2.png";
import apple from "../../assets/images/apple.png";
import uxdesign2 from "../../assets/images/uxdesign2.png";
import itsup2 from "../../assets/images/itsup2.png";
import ai2 from "../../assets/images/ai2.png";
import logodesign2 from "../../assets/images/logodesign2.png";
import MainBanner from "./MainBanner";
import { useState,useEffect } from "react";
import axios from "axios";
function Service_content() {
  const [service,setService] = useState([])

  const getservice=()=>{
    axios.get('https://mindcoadmin.v4masters.com/public/index.php/getservice').then((response)=>{
      // console.log(response)
      setService(response.data)
    }).catch((error)=>{
      console.log(error);
    })
  }

  useEffect(()=>{
    getservice()
  },[])

  return (
    <>
      <div>
        <MainBanner page="SERVICES" />
        <section className="our-service" id="our-service">
          <div className="our-service-section  container">
            <div className="bg-white">
              <div className="d-flex">
                <div className="left-side">
                  <h2 className="heading text-purple">Our Service</h2>
                  <p className="fw-medium text-black">
                    Are you looking for a custom app to solve a your business
                    need?
                  </p>
                  <div className="text-gray">
                    We offer impressive mobile app services to accelerate your
                    business to digital world on every device and platform.
                  </div>
                </div>
              </div>
              <div className="grid-container">
            {service.map(item=>
            <div className="grid-item">
                <div className="card h-100">
                  <div className="card-body">
                    <img className="image2" src={`https://smarto.s3.ap-south-1.amazonaws.com/mindcode/service/${item.image2}`} alt="" />
                    <img className="image1" src={`https://smarto.s3.ap-south-1.amazonaws.com/mindcode/service/${item.image1}`} alt="" />
                    <h5 className="mt-4">
                     {item.service_title}<br/>{item.service_title2}
                    </h5>
                  </div>
                </div>
              </div>
            )}

            </div>
            </div>
          </div>
        </section>
      </div>
      <Contactus />
    </>
  );
}

export default Service_content;
