import React, { useState, useEffect } from 'react'
import Ourservice from '../Common/our_service'
import Contactus from '../Common/Contactus'
import psicon from '../../assets/images/psicon.png'
import aiicon from '../../assets/images/aiicon.png'
import dicon from '../../assets/images/dicon.png'
import Group15 from '../../assets/images/Group 15.png'
import xdicon from '../../assets/images/xdicon.png'
import htmlicon from '../../assets/images/htmlicon.png'
import angular from '../../assets/images/angular.png'
import cssicon from '../../assets/images/cssicon.png'

// crousel images
import Ediary from '../../assets/images/c.png'
import verka from '../../assets/images/amp.png'
import pbpolice from '../../assets/images/ang.png'
import brahmakumaris from '../../assets/images/bootstap.png'
import evyapari from '../../assets/images/docker.png'
import vidhyashree from '../../assets/images/ios.png'
import jukedock from '../../assets/images/js.png'
import wonderautomation from '../../assets/images/lv.png'
import fedex from '../../assets/images/mongodb.png'
import erealto from '../../assets/images/mysql.jpg'
import TheTimesGroup from '../../assets/images/react.png'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//about-us section image
import aboutus from '../../assets/images/about-us-image.png'


//founder 
import skype from '../../assets/images/Skype.svg'

import { NavLink } from 'react-router-dom'
import axios from 'axios'
function Banner() {
  const [crousalData, setCrousalData] = useState([])


  // const handlesubmit = () => {
  //   axios.post('https://mindcoadmin.v4masters.com/public/index.php/internship', form)
  //     .then((response) => {
  //       setCrousalData(response.data)

  //     }).catch((error) => {
  //       console.log(error)
  //     })

  // }

  const getClient = () => {
    axios.get(`https://mindcoadmin.v4masters.com/public/index.php/getcrousalclient`,)
      .then((response) => {
        console.log('user', response)
        setCrousalData(response.data)
      }).catch((error) => {
      })
  }
  useEffect(() => {
    getClient()

  }, [])


  // useEffect(() => {
  //   // Set the navigation text using the FontAwesome icons
  //   setNavText([
  //    "<" ,
  //    ">"
  //   ]);
  // }, []);
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  return (
    <>
      {/* <section id="main-banner">
        <div className="banner-section-inner container">
          <div className="header-grid d-flex">
            <div className="left-side text-white">
              <h1 className="fw-medium">MINDCODE</h1>
              <h3 className="">A start up road & the Enterprises abode</h3>
              <p className="fw-medium">
                Let's turn your brand into a successfull buisness
              </p>

              <div className="d-flex">
                <NavLink to={"/"} onClick={() => scrollToSection("our-service")} className="btn btn-black text-white">
                  {" "}
                  Get Started{" "}
                </NavLink>
              </div>
            </div>
            <div className="solar-system mb-4 mt-5 mb-lg-0">
              <div id="sun"></div>

              <div className="orbit mercury-orbit"></div>
              <div className="mercury-spin">
                <div id="mercury" className="d-flex">
                  <img src={psicon} alt="" height="40" />
                </div>
                <div id="mercury" className="mercury2 d-flex">
                  <img src={aiicon} alt="" height="40" />
                </div>
              </div>

              <div className="orbit venus-orbit"></div>
              <div className="venus-spin">
                <div id="venus" className="d-flex bg-white">
                  <img src={dicon} alt="" height="40" />
                </div>
                <div id="venus" className="venus2 d-flex">
                  <img src={Group15} alt="" height="40" />
                </div>
                <div id="venus" className="venus3 d-flex">
                  <img src={xdicon} alt="" height="40" />
                </div>
              </div>

              <div className="orbit earth-orbit"></div>
              <div className="earth-spin">
                <div id="earth" className="d-flex bg-white">
                  <img src={htmlicon} alt="" height="40" />
                </div>
                <div
                  id="earth"
                  className="earth2 d-flex align-items-center bg-white justify-content-center"
                >
                  <img src={angular} alt="" height="40" />
                </div>
                <div
                  id="earth"
                  className="earth3 d-flex align-items-center bg-white justify-content-center"
                >
                  <img src={cssicon} alt="" height="40" />
                </div>
              </div>

             <div className="orbit mars-orbit"></div>
              <div className="mars-spin">
                <div id="mars" className="d-flex bg-white">
                  <img src={shopify} alt="" height="40" />
                </div>
                <div id="mars" className="mars3 bg-white d-flex">
                  <img src={appleicon} alt="" />
                </div>
                <div id="mars" className="mars2 bg-white d-flex">
                  <img src={reacticon} alt="" />
                </div>
                <div id="mars" className="mars4 bg-white d-flex">
                  <img src={javascript} alt="" />
                </div>
              </div>
              <div className="orbit jupitar-orbit"></div>
              <div className="jupitar-spin">
                <div id="jupitar" className="jupitar1 d-flex bg-white">
                  <img src={wordpress} alt="" height="40" />
                </div>
                <div id="jupitar" className="jupitar2 bg-white d-flex">
                  <img src={android} alt="" />
                </div>
                <div id="jupitar" className="jupitar3 bg-white d-flex">
                  <img src={metaicon} alt="" />
                </div>
              </div> 
            </div>
          </div>
        </div>
      </section> */}
          <section id="main-banner">
        <div className="banner-section-inner container">
          <div className="header-grid d-flex">
            <div className="left-side text-white">
              <h1 className="fw-medium">MINDCODE</h1>
              <h3 className="">A start up road & the Enterprises abode !</h3>
              <p className="fw-medium">
                Let's turn your brand into a successfull buisness
              </p>

              <div className="d-flex">
                <NavLink to={"/"} onClick={() => scrollToSection("our-service")} className="btn btn-black text-white">
                  Get Started
                </NavLink>
              </div>
            </div>
            <div className="solar-system mb-4 mt-5 mb-lg-0">
              <div id="sun"></div>

              <div className="orbit mercury-orbit"></div>
              <div className="mercury-spin">
                <div id="mercury" className="d-flex">
                  <img src={psicon} alt="" height="40" />
                </div>
                <div id="mercury" className="mercury2 d-flex">
                  <img src={aiicon} alt="" height="40" />
                </div>
              </div>

              <div className="orbit venus-orbit"></div>
              <div className="venus-spin">
                <div id="venus" className="d-flex bg-white">
                  <img src={dicon} alt="" height="40" />
                </div>
                <div id="venus" className="venus2 d-flex">
                  <img src={Group15} alt="" height="40" />
                </div>
                <div id="venus" className="venus3 d-flex">
                  <img src={xdicon} alt="" height="40" />
                </div>
              </div>

              <div className="orbit earth-orbit"></div>
              <div className="earth-spin">
                <div id="earth" className="d-flex bg-white">
                  <img src={htmlicon} alt="" height="40" />
                </div>
                <div
                  id="earth"
                  className="earth2 d-flex align-items-center bg-white justify-content-center"
                >
                  <img src={angular} alt="" height="40" />
                </div>
                <div
                  id="earth"
                  className="earth3 d-flex align-items-center bg-white justify-content-center"
                >
                  <img src={cssicon} alt="" height="40" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="section1" id="crousal-section">
        <div className="crousal-inner d-flex">
          <div className="ourclient-head">
            <h3 className="fw-semi-bold">Technology  we used</h3>

          </div>
          <div className=" crousal-side2">
            <OwlCarousel className="owl-one" items={5}
              loop
              dots={false}
              autoplay={true}
              autoplaySpeed={3000}
              autoplayTimeout={3000}

              margin={8}
              responsive={{
                0: {
                  items: 2
                },
                600: {
                  items: 3
                },
                1000: {
                  items: 5
                }
              }}>
              <div className="item "><div className="inner-item"><img src={Ediary} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={verka} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={pbpolice} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={brahmakumaris} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={evyapari} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={jukedock} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={vidhyashree} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={wonderautomation} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={fedex} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={erealto} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={TheTimesGroup} alt="" /></div></div>
              {/* <div className="item "><div className="inner-item"><img src={Group57} alt="" /></div></div> */}
            </OwlCarousel>
            {/* <div className="owl-one owl-carousel owl-theme">
              <div className="item "><div className="inner-item"><img src={Group56} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={Group57} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={Group58} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={Group59} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={Group57} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={Group57} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={Group57} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={Group57} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={Group57} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={Group57} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={Group57} alt="" /></div></div>
              <div className="item "><div className="inner-item"><img src={Group57} alt="" /></div></div>
            </div> */}

          </div>
        </div>
      </section>

      <Ourservice />

      <section className="section3 bg-white" id="about-us">
        <div className="about-us-inner container">
          <div className="d-flex">
            <div className="left">
              <h2 className="text-purple fw-semi-bold">About us</h2>
              <p className="fw-medium">
                Ever wondered how we ensure success for both MindCodeLab and our
                clients?
              </p>
              <div className="text-gray">
                At Mind CodeLab, we believe in Vasudev Kutumbkam, where the whole
                world is our family. Dive into our story as we serve our global
                family with creativity and transparency. With a decade of
                expertise in Designing, Developing, Marketing, and Branding,
                Mind Code Lab Pvt Ltd has witnessed phenomenal growth since
                2013.
              </div>
              <div className="d-flex">
                <NavLink to={'/about#main-banner-about'} className="btn btn-black text-white">
                  Read More
                </NavLink>
              </div>
            </div>
            <div className="right">
              <img src={aboutus} alt="" />
              <div className="experience">
                <h3 className="fw-semi-bold ten">10 +</h3>
                <h4 className="fw-semi-bold">Experience</h4>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="section2 section4" id="our-feature">
        <div className="our-quality-section container text-white">
          <h2 className="fw-semi-bold">We are Featured in ....</h2>
          <div className="text-white">
            At Mind Code, With a decade of expertise in Designing, Developing,
            Marketing, and Branding, <br />
            Mind Code Lab Pvt Ltd has witnessed phenomenal growth since 2013.
          </div>

          <OwlCarousel items={1} loop
            dots={false}
            autoplay={true}
            autoplaySpeed={3000}
            nav={true}
            navText={[
              '<i class="fa-solid fa-chevron-left"></i>',
              '<i class="fa-solid fa-chevron-right"></i>',
            ]}

            speed={500}
            margin={8} className="owl-two owl-carousel owl-theme" id="crousal2">
            {crousalData.map(item =>
              <div className="item">
                <div className="item1 image-section">
                  <div className="inner-image">
                    <img src={`https://smarto.s3.ap-south-1.amazonaws.com/mindcode/client/${item.client_logo}`} alt="" />
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            )}

            
          </OwlCarousel>
          {/* <div className="d-flex">
            <button className="arrow prev bg-transparent border-0 text-white" >
              <svg xmlns="http://www.w3.org/2000/svg" fill='currentColor' height={25} viewBox="0 0 320 512"><path d="M34.5 239L228.9 44.7c9.4-9.4 24.6-9.4 33.9 0l22.7 22.7c9.4 9.4 9.4 24.5 0 33.9L131.5 256l154 154.8c9.3 9.4 9.3 24.5 0 33.9l-22.7 22.7c-9.4 9.4-24.6 9.4-33.9 0L34.5 273c-9.4-9.4-9.4-24.6 0-33.9z" /></svg>
            </button>
            <button className="arrow next bg-transparent border-0 text-white" >
              <svg xmlns="http://www.w3.org/2000/svg" fill='currentColor' height={25} viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
            </button>
          </div> */}
        </div>
      </section>

      <Contactus />


      <section className="section2 section6" id="our-founder">
        <div className="founder-section container d-flex">
          <h2 className="text-white fw-semi-bold">About CEO</h2>
          <div className="text-white">
            At MindCode, we believe in Vasudev Kutumbkam, where the whole world
            is our family. Dive <br />
            into our story as we serve our global family with creativity and
            transparency.
          </div>
          <div className="about-ceo">
            <div className="image-section"></div>
            <div className="text-section bg-white">
              <div>
                <h4 className="fw-bold">Kapil Partap</h4>
                <h6 className="fw-medium">CEO & Founder</h6>
              </div>
              <div>
                <h5 className="fw-bold">
                  Ready to take your Business to <br /> the Next Level?
                </h5>
                <h6 className="text-gray fw-medium">
                  Let’s scale it with custom software.
                </h6>
              </div>
              <span className="d-flex">
                <a href="skype:live:kapilpartap?call">
                  {" "}
                  <img src={skype} alt="" />
                </a>
                <div>
                  <div className="discuss-about text-gray">
                    Discuss about project!
                  </div>
                  <span className="fw-medium">Let’s Connect</span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default Banner;