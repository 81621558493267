import React from "react";
import Header from "../Common/Header";
import HomeContent from "../page_content/Home_content";
import Footer from "../Common/Footer";

function Home() {
  return (
    <div>
      <title>MindCodeLab </title>
      <Header />
      <HomeContent />
      <Footer />
    </div>
  );
}

export default Home;
