import React, { useEffect, useState } from "react";
// our service image
// import android2 from '../../assets/images/android2.png'
// import vector1 from '../../assets/images/Vector (1).png'
// import vector2 from '../../assets/images/Vector (2).png'
// import vector3 from '../../assets/images/Vector (3).png'
// import vector4 from '../../assets/images/Vector (4).png'
// import vector5 from '../../assets/images/Vector (5).png'
// import vector6 from '../../assets/images/Vector (6).png'
// import vector7 from '../../assets/images/Vector (7).png'
// import webdesign2 from '../../assets/images/webdesign2.png'
// import digitalmarketing2 from '../../assets/images/digitalmarketing2.png'
// import iosapp2 from '../../assets/images/iosapp2.png'
// import apple from '../../assets/images/apple.png'
// import uxdesign2 from '../../assets/images/uxdesign2.png'
// import itsup2 from '../../assets/images/itsup2.png'
// import ai2 from '../../assets/images/ai2.png'
// import logodesign2 from '../../assets/images/logodesign2.png'
import { NavLink } from "react-router-dom";
import axios from "axios";

// const scrollToSection = (id) => {

//   const element = document.getElementById(id);
//   if (element) {
//     element.scrollIntoView({ behavior: 'smooth', block: 'start' });
//   }
// };

function Ourservice() {
  const [service, setService] = useState([]);

  const getservice = () => {
    axios
      .get("https://mindcoadmin.v4masters.com/public/index.php/getservice")
      .then((response) => {
        // console.log(response)
        setService(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getservice();
  }, []);

  return (
    <>
      <section className="section2" id="our-service">
        <div className="our-service-section container">
          <div className="bg-black">
            <div className="d-flex">
              <div className="left-side">
                <h2 className="fw-semi-bold">Our Service</h2>
                <p className="fw-medium">
                  Curious about how we can revitalize your brand?
                </p>
                <div className="letsdive">
                  Let's dive into your project and utilize the following IT
                  tools to hit your target and establish your business as a
                  lasting brand!
                </div>
                <div className="d-flex">
                  <NavLink
                    to={"/service#main-banner-about"}
                    className="btn btn-black text-white border-white"
                  >
                    Read More
                  </NavLink>
                </div>
              </div>
              <div className="right-side">
                <div className="needservice">
                  <p>Need our Service?</p>
                  <div className="d-flex">
                    <a
                      href="https://wa.me/+919896570012"
                      className="btn btn-purple text-white"
                    >
                      Discuss about Project
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-container">
              {service.map((item) => (
                <div className="grid-item">
                  <div className="card h-100">
                    <div className="card-body">
                      <img
                        className="image2"
                        src={`https://smarto.s3.ap-south-1.amazonaws.com/mindcode/service/${item.image2}`}
                        alt="our service"
                      />
                      <img
                        className="image1"
                        src={`https://smarto.s3.ap-south-1.amazonaws.com/mindcode/service/${item.image1}`}
                        alt="our service"
                      />
                      <h5 className="mt-4">
                        {item.service_title}
                        <br />
                        {item.service_title2}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ourservice;
