import React, { useEffect, useState } from "react";

import Contactus from "../Common/Contactus";
import MainBanner from "./MainBanner";
import intershipduration from "../../assets/images/intershipduration.svg";
import intershiptype from "../../assets/images/intershiptype.svg";
import booktimeslot from "../../assets/images/booktimeslot.svg";
import bookseat from "../../assets/images/book-seat.svg";
import finishyourprogress from "../../assets/images/finish-your-progress.svg";
import personalinfo from "../../assets/images/personal-info-icon.png";
import axios from "axios";
import { NavLink } from "react-router-dom";

function Intern_content() {
  const [form, setForm] = useState({});
  const [step, setStep] = useState(0);
  const [skipStep, setSkipStep] = useState(false);
  const [intenId, setInternId] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [userData, setUserData] = useState({});

  const handleinput = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => {
      const updatedForm = { ...prevForm, [name]: value };
      validateForm(updatedForm);
      return updatedForm;
    });
    console.log(e.target.name, value);
    if (e.target.name === "type" && value === "Full Day") {
      setSkipStep(true);
    } else {
      setSkipStep(false);
    }
  };
  console.log(form.email);
  console.log(step);
  console.log(skipStep);

  const requiredFields = {
    0: ["duration"],
    1: ["name", "email", "phone", "college", "course", "semester"],
    2: ["type"],
    3: ["timing"],
  };

  const validateForm = (form) => {
    const currentStepFields = requiredFields[step];
    const isValid = currentStepFields.every((field) => !!form[field]);
    setIsFormValid(isValid);
  };
  const handleduration = () => {
    setStep(step + 1);
  };
  const handleprev = () => {
    setStep(step - 1);
  };

  const handledpersonal = () => {
    setStep(2);
  };
  const handlesubtype = () => {
    console.log(skipStep);
    if (skipStep) {
      handlesubmit();
      setStep(4);
    } else {
      setStep(step + 1);
    }
  };

  const handlesubmit = () => {
    axios
      .post(
        "https://mindcoadmin.v4masters.com/public/index.php/internship",
        form
      )
      .then((response) => {
        setInternId(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setStep(step + 1);
  };

  const getIntern = () => {
    axios
      .get(
        `https://mindcoadmin.v4masters.com/public/index.php/getinternship/${intenId}`
      )
      .then((response) => {
        console.log("user", response);
        setUserData(response.data[0]);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getIntern();
    setIsFormValid(false);
  }, [intenId, step]);

  console.log("mnjn", userData);

  return (
    <>
      <div>
        <MainBanner page="INTERNSHIP" />
        {step === 0 ? (
          <section className="internship" id="rounded-box-lg">
            <div className="container">
              <div className="bg-white">
                <div className="top-grid">
                  <div className="left">
                    <h2 className="text-purple">REGISTRATION STARTED</h2>
                    <p className="fw-medium">FOR INTERNSHIP PROGRAM 2024</p>
                  </div>
                  <div className="right">
                    <p className="text-gray fw-medium">
                      Fill all form field to go to next step
                    </p>
                  </div>
                </div>
                <div className="intership-grid">
                  <div className="left-side">
                    <ul>
                      <li className="d-flex">
                        <div className="image-section bg-purple">
                          <img src={intershipduration} alt='intershipduration' />
                        </div>
                        <div className="text-section">
                          <h2 className="text-purple">01</h2>
                          <h6 className="fw-medium">Internship Duration</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2 ">
                          <img src={personalinfo} alt='personalinfo' />
                        </div>
                        <div className="text-section type2">
                          <h2 className>02</h2>
                          <h6 className="fw-medium">Personal Information</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2 ">
                          <img src={intershiptype} alt='intershiptype' />
                        </div>
                        <div className="text-section type2">
                          <h2 className>03</h2>
                          <h6 className="fw-medium">Internship Type</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2 ">
                          <img src={booktimeslot} alt='booktimeslot' />
                        </div>
                        <div className="text-section type2">
                          <h2 className>04</h2>
                          <h6 className="fw-medium">Book Timing Slot</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2 ">
                          <img src={bookseat} alt='bookseat' />
                        </div>
                        <div className="text-section type2">
                          <h2 className>05</h2>
                          <h6 className="fw-medium">Book a Seat</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2 ">
                          <img src={finishyourprogress} alt='finishyourprogress' />
                        </div>
                        <div className="text-section type2">
                          <h2 className>06</h2>
                          <h6 className="fw-medium">Finish Your Progress</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="right-side">
                    <p className="fw-medium">Select Internship Duration</p>
                    <div className="form-top">
                      <form className="intership-radio text-gray">
                        <div className="form-group">
                          <label>
                            <input
                              type="radio"
                              name="duration"
                              defaultValue="6-Weeks"
                              onClick={handleinput}
                            />
                            6 Weeks
                          </label>
                        </div>
                        <div className="form-group">
                          <label>
                            <input
                              type="radio"
                              name="duration"
                              defaultValue="3-Months"
                              onClick={handleinput}
                            />
                            3 Months
                          </label>
                        </div>
                        <div className="form-group">
                          <label>
                            <input
                              type="radio"
                              name="duration"
                              defaultValue=" 6-Months"
                              onClick={handleinput}
                            />
                            6 Months
                          </label>
                        </div>
                      </form>
                    </div>

                    <div className="d-flex btn-wraper">
                      <button
                        className="btn btn-black text-white"
                        type="submit"
                        onClick={handleduration}
                        disabled={!isFormValid}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : step === 1 ? (
          <section className="internship step2" id="rounded-box-lg">
            <div className="container">
              <div className="bg-white">
                <div className="top-grid">
                  <div className="left">
                    <h2 className="text-purple">REGISTRATION STARTED</h2>
                    <p className="fw-medium">FOR INTERNSHIP PROGRAM 2024</p>
                  </div>
                  <div className="right">
                    <p className="text-gray fw-medium">
                      Fill all form field to go to next step
                    </p>
                  </div>
                </div>
                <div className="intership-grid">
                  <div className="left-side">
                    <ul>
                      <li className="d-flex">
                        <div className="image-section bg-purple">
                          <img src={intershipduration} alt='intershipduration' />
                        </div>
                        <div className="text-section">
                          <h2 className="text-purple">01</h2>
                          <h6 className="fw-medium">Internship Duration</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section  bg-purple">
                          <img src={personalinfo} alt='personalinfo' />
                        </div>
                        <div className="text-section type2">
                          <h2 className="text-purple">02</h2>
                          <h6 className="fw-medium">Personal Information</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2 ">
                          <img src={intershiptype} alt='intershiptype' />
                        </div>
                        <div className="text-section type2">
                          <h2 className>03</h2>
                          <h6 className="fw-medium">Internship Type</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2 ">
                          <img src={booktimeslot} alt='booktimeslot' />
                        </div>
                        <div className="text-section type2">
                          <h2 className>04</h2>
                          <h6 className="fw-medium">Book Timing Slot</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2 ">
                          <img src={bookseat} alt='bookseat' />
                        </div>
                        <div className="text-section type2">
                          <h2 className>05</h2>
                          <h6 className="fw-medium">Book a Seat</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2 ">
                          <img src={finishyourprogress} alt='finishyourprogress' />
                        </div>
                        <div className="text-section type2">
                          <h2 className>06</h2>
                          <h6 className="fw-medium">Finish Your Progress</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="right-side">
                    <p className="fw-medium">Personal Information</p>
                    <div className="form-top">
                      <div className="form-container">
                        <div className="form-group">
                          <input
                            type="text"
                            id="name"
                            placeholder="MCL"
                            name="name"
                            required
                            onChange={handleinput}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            placeholder="info@gmail.com"
                            id="email"
                            name="email"
                            required
                            onChange={handleinput}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="tel"
                            placeholder="+91 9896570012"
                            id="phone"
                            name="phone"
                            required
                            onChange={handleinput}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Enter Your College Name"
                            id="phone"
                            name="college"
                            required
                            onChange={handleinput}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Course"
                            id="phone"
                            name="course"
                            required
                            onChange={handleinput}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Semester"
                            id="phone"
                            name="semester"
                            required
                            onChange={handleinput}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex btn-wraper">
                      <button
                        className="btn btn-black text-white"
                        type="submit"
                        onClick={handleprev}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-black text-white"
                        type="submit"
                        onClick={handledpersonal}
                        disabled={!isFormValid}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : step === 2 ? (
          <section className="internship step2" id="rounded-box-lg">
            <div className="container">
              <div className="bg-white">
                <div className="top-grid">
                  <div className="left">
                    <h2 className="text-purple">REGISTRATION STARTED</h2>
                    <p className="fw-medium">FOR INTERNSHIP PROGRAM 2024</p>
                  </div>
                  <div className="right">
                    <p className="text-gray fw-medium">
                      Fill all form field to go to next step
                    </p>
                  </div>
                </div>
                <div className="intership-grid">
                  <div className="left-side">
                    <ul>
                      <li className="d-flex">
                        <div className="image-section bg-purple">
                          <img src={intershipduration} alt='intershipduration' />
                        </div>
                        <div className="text-section">
                          <h2 className="text-purple">01</h2>
                          <h6 className="fw-medium">Internship Duration</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section bg-purple">
                          <img src={personalinfo} alt='personalinfo' />
                        </div>
                        <div className="text-section type2 ">
                          <h2 className="text-purple">02</h2>
                          <h6 className="fw-medium">Personal Information</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section  bg-purple">
                          <img src={intershiptype} alt='intershiptype' />
                        </div>
                        <div className="text-section type2">
                          <h2 className="text-purple">03</h2>
                          <h6 className="fw-medium">Internship Type</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2 ">
                          <img src={booktimeslot} alt='booktimeslot' />
                        </div>
                        <div className="text-section type2">
                          <h2 className>04</h2>
                          <h6 className="fw-medium">Book Timing Slot</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2 ">
                          <img src={bookseat} alt='bookseat' />
                        </div>
                        <div className="text-section type2">
                          <h2 className>05</h2>
                          <h6 className="fw-medium">Book a Seat</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2 ">
                          <img src={finishyourprogress} alt='finishyourprogress' />
                        </div>
                        <div className="text-section type2">
                          <h2 className>06</h2>
                          <h6 className="fw-medium">Finish Your Progress</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="right-side">
                    <p className="fw-medium">Select Training Type</p>
                    <div className="form-top">
                      <form className="intership-radio text-gray">
                        <div className="form-group">
                          <label>
                            <input
                              type="radio"
                              name="type"
                              defaultValue="Full Day"
                              onClick={handleinput}
                            />
                            Full Day
                          </label>
                        </div>
                        <div className="form-group">
                          <label>
                            <input
                              type="radio"
                              name="type"
                              defaultValue="2 hours"
                              onClick={handleinput}
                            />
                            2 Hours
                          </label>
                        </div>
                      </form>
                    </div>

                    <div className="d-flex btn-wraper">
                      <button
                        className="btn btn-black text-white"
                        type="submit"
                        onClick={handleprev}
                      >
                        Prev
                      </button>

                      <button
                        className="btn btn-black text-white"
                        type="submit"
                        onClick={handlesubtype}
                        disabled={!isFormValid}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : step === 3 ? (
          <section className="internship step2" id="rounded-box-lg">
            <div className="container">
              <div className="bg-white">
                <div className="top-grid">
                  <div className="left">
                    <h2 className="text-purple">REGISTRATION STARTED</h2>
                    <p className="fw-medium">FOR INTERNSHIP PROGRAM 2024</p>
                  </div>
                  <div className="right">
                    <p className="text-gray fw-medium">
                      Fill all form field to go to next step
                    </p>
                  </div>
                </div>
                <div className="intership-grid">
                  <div className="left-side">
                    <ul>
                      <li className="d-flex">
                        <div className="image-section bg-purple">
                          <img src={intershipduration} alt='intershipduration' />
                        </div>
                        <div className="text-section">
                          <h2 className="text-purple">01</h2>
                          <h6 className="fw-medium">Internship Duration</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section  bg-purple ">
                          <img src={personalinfo} alt='personalinfo' />
                        </div>
                        <div className="text-section type2">
                          <h2 className="text-purple">02</h2>
                          <h6 className="fw-medium">Personal Information</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section  bg-purple">
                          <img src={intershiptype} alt='intershiptype' />
                        </div>
                        <div className="text-section type2">
                          <h2 className="text-purple">03</h2>
                          <h6 className="fw-medium">Internship Type</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section  bg-purple">
                          <img src={booktimeslot} alt='booktimeslot' />
                        </div>
                        <div className="text-section">
                          <h2 className="text-purple">04</h2>
                          <h6 className="fw-medium">Book Timing Slot</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2 ">
                          <img src={bookseat} alt='bookseat' />
                        </div>
                        <div className="text-section type2">
                          <h2 className>05</h2>
                          <h6 className="fw-medium">Book a Seat</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section  type2">
                          <img src={finishyourprogress} alt='finishyourprogress' />
                        </div>
                        <div className="text-section type2">
                          <h2 className="">06</h2>
                          <h6 className="fw-medium">Finish Your Progress</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="right-side">
                    <p className="fw-medium">Select Training Type</p>
                    <div className="form-top">
                      <form className="intership-radio text-gray">
                        <div className="form-group">
                          <label>
                            <input
                              type="radio"
                              name="timing"
                              value="8:00 am to 10:00 am"
                              onClick={handleinput}
                            />
                            8Am - 10Am
                          </label>
                        </div>
                        <div className="form-group">
                          <label>
                            <input
                              type="radio"
                              name="timing"
                              value="10:00 am to 12:00 am"
                              onClick={handleinput}
                            />
                            10Am - 12Am
                          </label>
                        </div>
                        <div className="form-group">
                          <label>
                            <input
                              type="radio"
                              name="timing"
                              value="12:00 am to 2:00 pm"
                              onClick={handleinput}
                            />
                            12Am - 2Pm
                          </label>
                        </div>
                        <div className="form-group">
                          <label>
                            <input
                              type="radio"
                              name="timing"
                              value="2:00 pm to 4:00 pm"
                              onClick={handleinput}
                            />
                            2Pm - 4Pm
                          </label>
                        </div>
                        <div className="form-group">
                          <label>
                            <input
                              type="radio"
                              name="timing"
                              value="4:00 pm to 6:00 pm"
                              onClick={handleinput}
                            />
                            4Pm - 6Pm
                          </label>
                        </div>
                        <div className="form-group">
                          <label>
                            <input
                              type="radio"
                              name="timing"
                              value="6:00 pm to 8:00 pm"
                              onClick={handleinput}
                            />
                            6Pm - 8Pm
                          </label>
                        </div>
                      </form>
                    </div>

                    <div className="d-flex btn-wraper">
                      <button
                        className="btn btn-black text-white"
                        type="submit"
                        onClick={handleprev}
                      >
                        Prev
                      </button>

                      <button
                        className="btn btn-black text-white"
                        type="submit"
                        onClick={handlesubmit}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : step === 4 ? (
          <section className="internship" id="rounded-box-lg">
            <div className="container">
              <div className="bg-white">
                <div className="top-grid">
                  <div className="left">
                    <h2 className="text-purple">REGISTRATION STARTED</h2>
                    <p className="fw-medium">FOR INTERNSHIP PROGRAM 2024</p>
                  </div>
                  <div className="right">
                    <p className="text-gray fw-medium">
                      Fill all form field to go to next step
                    </p>
                  </div>
                </div>
                <div className="intership-grid">
                  <div className="left-side">
                    <ul>
                      <li className="d-flex">
                        <div className="image-section bg-purple">
                          <img src={intershipduration} alt='intershipduration' />
                        </div>
                        <div className="text-section">
                          <h2 className="text-purple">01</h2>
                          <h6 className="fw-medium">Internship Duration</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section bg-purple ">
                          <img src={personalinfo} alt='personalinfo' />
                        </div>
                        <div className="text-section type1 ">
                          <h2 className="text-purple">02</h2>
                          <h6 className="fw-medium">Personal Information</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section bg-purple ">
                          <img src={intershiptype} alt='intershiptype' />
                        </div>
                        <div className="text-section ">
                          <h2 className="text-purple">03</h2>
                          <h6 className="fw-medium">Internship Type</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section bg-purple ">
                          <img src={booktimeslot} alt='booktimeslot' />
                        </div>
                        <div className="text-section ">
                          <h2 className="text-purple">04</h2>
                          <h6 className="fw-medium">Book Timing Slot</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section bg-purple ">
                          <img src={bookseat} alt='bookseat' />
                        </div>
                        <div className="text-section ">
                          <h2 className="text-purple">05</h2>
                          <h6 className="fw-medium">Book a Seat</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type2">
                          <img src={finishyourprogress} alt='finishyourprogress' />
                        </div>
                        <div className="text-section type2 ">
                          <h2 className="">06</h2>
                          <h6 className="fw-medium">Finish Your Progress</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="right-side">
                    <p className="fw-medium">Book Your Seat</p>
                    <div className="form-top">
                      <ul className="book-seat">
                        <li className="list-book-seat">
                          <div className="fw-semi-bold">Duration</div>
                          <div>
                            {userData.duration ? (
                              userData.duration
                            ) : (
                              <div className="loader spinner"></div>
                            )}
                          </div>
                        </li>
                        <li className="list-book-seat">
                          <div className="fw-semi-bold">Name</div>
                          <div>
                            {userData.name ? (
                              userData.name
                            ) : (
                              <div className="loader spinner"></div>
                            )}
                          </div>
                        </li>
                        <li className="list-book-seat">
                          <div className="fw-semi-bold">Email</div>
                          <div>
                            {userData.email ? (
                              userData.email
                            ) : (
                              <div className="loader spinner"></div>
                            )}
                          </div>
                        </li>
                        <li className="list-book-seat">
                          <div className="fw-semi-bold">Phone</div>
                          <div>
                            {userData.phone ? (
                              userData.phone
                            ) : (
                              <div className="loader spinner"></div>
                            )}
                          </div>
                        </li>
                        <li className="list-book-seat">
                          <div className="fw-semi-bold">College</div>
                          <div>
                            {userData.college ? (
                              userData.college
                            ) : (
                              <div className="loader spinner"></div>
                            )}
                          </div>
                        </li>
                        <li className="list-book-seat">
                          <div className="fw-semi-bold">Courses</div>
                          <div>
                            {userData.course ? (
                              userData.course
                            ) : (
                              <div className="loader spinner"></div>
                            )}
                          </div>
                        </li>
                        <li className="list-book-seat">
                          <div className="fw-semi-bold">Semester</div>
                          <div>
                            {userData.semester ? (
                              userData.semester
                            ) : (
                              <div className="loader spinner"></div>
                            )}
                          </div>
                        </li>
                        <li className="list-book-seat">
                          <div className="fw-semi-bold">Type</div>
                          <div>
                            {userData.type ? (
                              userData.type
                            ) : (
                              <div className="loader spinner"></div>
                            )}
                          </div>
                        </li>
                        {userData.timing ? (
                          <li className="list-book-seat">
                            <div className="fw-semi-bold">Timing</div>
                            <div>
                              {userData.timing ? (
                                userData.timing
                              ) : (
                                <div className="loader spinner"></div>
                              )}
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                    <div className="d-flex btn-wraper">
                      <button
                        className="btn btn-black text-white"
                        type="submit"
                        onClick={handleduration}
                      >
                        Finish
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : step === 5 ? (
          <section className="internship" id="rounded-box-lg">
            <div className="container">
              <div className="bg-white">
                <div className="top-grid">
                  <div className="left">
                    <h2 className="text-purple">REGISTRATION STARTED</h2>
                    <p className="fw-medium">FOR INTERNSHIP PROGRAM 2024</p>
                  </div>
                  <div className="right">
                    <p className="text-gray fw-medium">
                      Fill all form field to go to next step
                    </p>
                  </div>
                </div>
                <div className="intership-grid">
                  <div className="left-side">
                    <ul>
                      <li className="d-flex">
                        <div className="image-section bg-purple">
                          <img src={intershipduration} alt='intershipduration' />
                        </div>
                        <div className="text-section">
                          <h2 className="text-purple">01</h2>
                          <h6 className="fw-medium">Internship Duration</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section bg-purple ">
                          <img src={personalinfo} alt='personalinfo' />
                        </div>
                        <div className="text-section type1 ">
                          <h2 className="text-purple">02</h2>
                          <h6 className="fw-medium">Personal Information</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section bg-purple ">
                          <img src={intershiptype} alt='intershiptype' />
                        </div>
                        <div className="text-section ">
                          <h2 className="text-purple">03</h2>
                          <h6 className="fw-medium">Internship Type</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section bg-purple ">
                          <img src={booktimeslot} alt='booktimeslot' />
                        </div>
                        <div className="text-section ">
                          <h2 className="text-purple">04</h2>
                          <h6 className="fw-medium">Book Timing Slot</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section bg-purple ">
                          <img src={bookseat} alt='bookseat' />
                        </div>
                        <div className="text-section ">
                          <h2 className="text-purple">05</h2>
                          <h6 className="fw-medium">Book a Seat</h6>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="image-section type1 bg-purple ">
                          <img src={finishyourprogress} alt='finishyourprogress' />
                        </div>
                        <div className="text-section type1 ">
                          <h2 className="text-purple">06</h2>
                          <h6 className="fw-medium">Finish Your Progress</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="right-side">
                    <p className="fw-medium">
                      Congratulations You’ve Successfully Enrolled!{" "}
                    </p>
                    <div className="bg"></div>
                    <div className="d-flex btn-wraper">
                      <NavLink
                        to={"/"}
                        className="btn btn-black text-white"
                        type="submit"
                      >
                        Home
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <></>
        )}

        <Contactus />
      </div>
    </>
  );
}

export default Intern_content;
