import React from 'react'
import Header from '../Common/Header'
import ComercioContent from '../page_content/Comercio_content'
import Footer from '../Common/Footer'

function Comercio() {
  return (
    <div>
        <Header/>
        <ComercioContent/>
        <Footer/>
    </div>
  )
}

export default Comercio