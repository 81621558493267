import React from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import ProductContent from '../page_content/Product_content'

function Product() {
  return (
    <div>
      <title>Product </title>
        <Header/>
        <ProductContent/>
        <Footer/>
    </div>
  )
}

export default Product