import React, { useContext, useEffect, useState } from "react";
import Contactus from "../Common/Contactus";
import MainBanner from "./MainBanner";
import { contextData } from "../../App";
import axios from "axios";
function Event_content() {
  const [upcomingEvent,setEvent]=useState([]);
  const [prevEvent,setPrevEvent]=useState([]);
  const events = [];
  const prevEvents = [];

  const getEvent=()=>{
    axios.get('https://mindcoadmin.v4masters.com/public/index.php/getevent/').then((response)=>{

      for(let i=0;i<response.data.length;i++){
        if (response.data[i].status == 1) {
          events.push(response.data[i]);
        } else {
          prevEvents.push(response.data[i]);
        }
      }
      if (events.length > 0) {
        setEvent(events);
      }
      if (prevEvents.length > 0) {
        setPrevEvent(prevEvents);
      }

    
    }).catch((error)=>{

    })
  }

  useEffect(()=>{
     getEvent();
  },[])
  // let props = useContext(contextData);
  // const upcomingEvent = props[0].upcomingEvent;
  // const pastEvent = props[0].pastEvent;
  // console.log(props);



  return (
    <>
      <div>
        <MainBanner page="EVENTS" />
        <section className="our-service upcoming" id="our-service">
          <div className="our-service-section events  container">
            <div className="bg-white">
              <div>
                <h2 className="text-purple">Upcoming Events</h2>
                <p className="fw-medium">
                  Embrace Opportunity: A Pathway to Success
                </p>
                <div className="event-grid-container">
                  {upcomingEvent.map((item, index) => {
                    return (
                      <div className="card-container" key={index}>
                        <p className="text-purple">{item.uni_name} ( {item.event_name} )</p>
                        <p className="fw-medium">{item.date}</p>
                        <div className="text-gray">{item.topic}</div>
                      </div>
                    );
                  })}
                  { upcomingEvent.length===0 ? "Will be there soon ":"" }
       
                </div>
              </div>
              <div className="past-event">
                <h2 className="text-purple">Past Events</h2>
                <div className="event-grid-container">
                {prevEvent.map((items, index) => {
                    return (
                      <div className="card-container" key={index}>
                        <p className="text-purple">{items.uni_name} ( {items.event_name} )</p>
                        <p className="fw-medium">{items.date}</p>
                        <div className="text-gray">{items.topic}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Contactus />
      </div>
    </>
  );
}

export default Event_content;
