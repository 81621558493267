import React from 'react'
import Header from '../Common/Header'
import ContactContent from '../page_content/Contact_content'
import Footer from '../Common/Footer'
function Contact() {
  return (
    <div>
      <title>Contact Us </title>

        <Header />
        <ContactContent/>
        <Footer/>
    </div>
  )
}

export default Contact