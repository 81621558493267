/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Contactus from "../Common/Contactus";
import whiterightarrow from "../../assets/images/white-rightarrow.svg";
import blackrightarrow from "../../assets/images/blackrightarrow.svg";
import { NavLink } from "react-router-dom";
import MainBanner from "./MainBanner";
function Product_content() {
  return (
    <>
      <div>
        <MainBanner page="PRODUCTS" />
        <section className=" aboutsection2" id="our-product">
          <div className="container ">
            <div className="bg-white">
              <div className="grid-sec sec1">
                <div className="textsection">
                  <h2 className="text-purple">Our Products</h2>
                  <p className="fw-semi-bold">
                    Are you looking for business Solution?
                  </p>
                  <div>
                    IT solutions designed for your needs, from mobile to the
                    cloud, delivered as a service. Customizable IT solutions,
                    from the pocket to the cloud, delivered as a service.
                  </div>
                </div>
                <div className="box-size">
                  <div className="Comercio text-white">
                    <h3>Comercio</h3>
                  </div>
                </div>
                <div className="box-size">
                  <div className="Medico text-white">
                    <h3>Medico</h3>
                  </div>
                </div>
                <div className="box-size">
                  <div className="Transportation text-white">
                    <h3>Taxi &amp; Transportation</h3>
                  </div>
                </div>
                <div className="box-size">
                  <div className="Education text-white">
                    <h3>Education</h3>
                  </div>
                </div>
                <div className="box-size">
                  <div className="Comercio img2 text-white">
                    <h3>Examine</h3>
                  </div>
                </div>
                <div className="box-size">
                  <div className="Medico img2 text-white">
                    <h3>Real Estate</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="product-section" id="Comercio-affordable">
        <div className="container product-section-inner">
          <div className="product-inner-text">
            <div>
              <h2 className="fw-semi-bold">Comercio Affordable</h2>
              <p className="fw-medium">
                Online Marketplace:&nbsp;Comercio act as an online marketplace
                that allows buyers and sellers to connect with each other. They
                offer a platform for businesses to showcase their products or
                services, and customers can browse and purchase them.
              </p>
              <NavLink to={`/comercio/${1}#main-banner-about`} className="btn btn-black text-white">
                {" "}
                View Project
                <img src={whiterightarrow} alt="" />
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section className="product-section" id="Medico" >
        <div className="container product-section-inner">
          <div className="product-inner-text text-white">
            <div>
              <h2 className="fw-semi-bold">Medico</h2>
              <p className="fw-medium">
                A complete healthcare solution. Find NearBy Hospital,Booking
                Online Appointments,Proper Diagnosis and Medication
              </p>
              <a href="#" className="btn btn-black bg-white text-black">
                {" "}
                View Project <img src={blackrightarrow} alt />
              </a>
            </div>
          </div>
        </div>
      </section>

      <div>
        <section className="product-section" id="taxi">
          <div className="container product-section-inner">
            <div className="product-inner-text text-white">
              <div>
                <h2 className="fw-semi-bold">Taxi &amp; Transportation</h2>
                <p className="fw-medium">
                  Mindcodelab's trucking service is designed to meet the
                  transportation needs of businesses in a variety of industries.
                </p>
                <a
                  href="#"
                  className="btn btn-black bg-white text-black text-white"
                >
                  {" "}
                  View Project
                  <img src={blackrightarrow} alt />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="product-section" id="education">
          <div className="container product-section-inner">
            <div className="product-inner-text  text-white">
              <div>
                <h2 className="fw-semi-bold">Education</h2>
                <p className="fw-medium">
                  Mindcodelab offers ERP software solutions tailored to the
                  unique needs of schools and colleges. Our ERP software
                  provides a comprehensive suite of tools that enable
                  educational institutions to manage all aspects of their
                  operations, from admissions and student records to finance and
                  HR.
                </p>
                <a
                  href="#"
                  className="btn btn-black bg-white text-black text-white"
                >
                  {" "}
                  View Project
                  <img src={blackrightarrow} alt />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="product-section" id="Examen">
          <div className="container product-section-inner">
            <div className="product-inner-text text-white">
              <div>
                <h2 className="fw-semi-bold">Examen</h2>
                <p className="fw-medium">
                  No.1 Professional Driving School Software that assists Users
                  to become professional Drivers.
                </p>
                <a
                  href="#"
                  className="btn btn-black bg-white text-black bg-white text-white"
                >
                  {" "}
                  View Project
                  <img src={blackrightarrow} alt />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="product-section" id="realestate">
          <div className="container product-section-inner">
            <div className="product-inner-text text-white">
              <div>
                <h2 className="fw-semi-bold">Real Estate</h2>
                <p className="fw-medium">
                  Mindcodelab provides a range of real estate services to help
                  clients buy, sell,rent and manage properties. Our team of
                  experienced agents has a deep understanding of the local real
                  estate market and can provide expert guidance on pricing,
                  negotiations, and legal processes.
                </p>
                <a
                  href="#"
                  className="btn btn-black bg-white text-black bg-white text-white"
                >
                  {" "}
                  View Project
                  <img src={blackrightarrow} alt />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Contactus />
    </>
  );
}

export default Product_content;
