import React from 'react'
import intershipduration from '../../../assets/images/intershipduration.svg'
import intershiptype from '../../../assets/images/intershiptype.svg'
import booktimeslot from '../../../assets/images/booktimeslot.svg'
import bookseat from '../../../assets/images/book-seat.svg'
import finishyourprogress from '../../../assets/images/finish-your-progress.svg'
import personalinfo from '../../../assets/images/personal-info-icon.png'
import { useLocation } from 'react-router-dom'
function PersonalInfo() {
  const location = useLocation();
  const { duration,step } = location.state;
  return (
    <div>
         <h2>Personal Information</h2>
         <p>Selected Internship Duration: {duration} step : {step}</p>
<section className="internship step2" id="rounded-box-lg">
  <div className="container">
    <div className="bg-white">
      <div className="top-grid">
        <div className="left">
          <h2 className="text-purple">REGISTRATION STARTED</h2>
          <p className="fw-medium">FOR INTERNSHIP PROGRAM 2024</p>
        </div>
        <div className="right">
          <p className="text-gray fw-medium">Fill all form field to go to next step</p>
        </div>
      </div>
      <div className="intership-grid">
      <div className="left-side">
          <ul>
            <li className="d-flex">
              <div className="image-section bg-purple">
                <img src={intershipduration} alt />
              </div>
              <div className="text-section">
                <h2 className="text-purple">01</h2>
                <h6 className="fw-medium">Internship Duration</h6>
              </div>
            </li>
            <li className="d-flex">
              <div className="image-section type2 ">
                <img src={personalinfo} alt />
              </div>
              <div className="text-section type2">
                <h2 className>02</h2>
                <h6 className="fw-medium">Personal Information</h6>
              </div>
            </li>
            <li className="d-flex">
              <div className="image-section type2 ">
                <img src={intershiptype} alt />
              </div>
              <div className="text-section type2">
                <h2 className>03</h2>
                <h6 className="fw-medium">Internship Type</h6>
              </div>
            </li>
            <li className="d-flex">
              <div className="image-section type2 ">
                <img src={booktimeslot} alt />
              </div>
              <div className="text-section type2">
                <h2 className>04</h2>
                <h6 className="fw-medium">Book Timing Slot</h6>
              </div>
            </li>
            <li className="d-flex">
              <div className="image-section type2 ">
                <img src={bookseat} alt />
              </div>
              <div className="text-section type2">
                <h2 className>05</h2>
                <h6 className="fw-medium">Book a Seat</h6>
              </div>
            </li>
            <li className="d-flex">
              <div className="image-section type2 ">
                <img src={finishyourprogress} alt />
              </div>
              <div className="text-section type2">
                <h2 className>06</h2>
                <h6 className="fw-medium">Finish Your Progress</h6>
              </div>
            </li>
          </ul>
        </div>
        <div className="right-side">
          <p className="fw-medium">Personal Information</p>
          <div className="form-container">
            <div className="form-group">
              <input type="text" id="name" placeholder="MCL" name="name" required />
            </div>
            <div className="form-group">
              <input type="email" placeholder="info@gmail.com" id="email" name="email" required />
            </div>
            <div className="form-group">
              <input type="tel" placeholder="+91 9896570012" id="phone" name="phone" required />
            </div>
            <div className="form-group">
              <input type="text" placeholder="Enter Your College Name" id="phone" name="phone" required />
            </div>
            <div className="form-group">
              <select id="product" name="product">
                <option selected disabled>Select Course</option>
                <option value="product1">Select</option>
                <option value="product2">Product 2</option>
                <option value="product3">Product 3</option>
              </select>
            </div>
            <div className="form-group">
              <select id="product" name="product">
                <option selected disabled className="text-gray">Select Semester</option>
                <option value="product1">Select</option>
                <option value="product2">Product 2</option>
                <option value="product3">Product 3</option>
              </select>
            </div>
          </div>
          <div className="d-flex btn-wraper">
            <button className="btn btn-black text-white" type="submit">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default PersonalInfo