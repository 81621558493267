/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Contactus from "../Common/Contactus";
import whiterightarrow from "../../assets/images/white-rightarrow.svg";
import Comerciosingle from "../../assets/images/Comerciosingle.png";
import closeicon from "../../assets/images/closeicon.svg";
import MainBanner from "./MainBanner";
import axios from "axios";
import { useParams } from "react-router-dom";
import { error } from "jquery";

function Comercio_content() {

  const [product,setProduct] = useState([])
  let {id} = useParams()

  const getProduct=()=>{
    axios.get(`https://mindcoadmin.v4masters.com/public/index.php/getproduct/${id}`)
    .then((response)=>{
      console.log(response)
      setProduct(response.data)
    }).catch((error)=>console.log(error));
  }
  useEffect(()=>{
    getProduct()
  },[])
  return (
    <>
      <div>
        <MainBanner page="PRODUCTS" />
        <section className=" aboutsection2" id="single-product">
          <div className="container ">
            <div className="bg-white">
              <div className="text-section">
                <h2 className="text-purple">{product[0]?.product_title}</h2>
                <p className="fw-medium">{product[0]?.product_type}</p>
                <div>
                  Online Marketplace:&nbsp;{product[0]?.online_market}
                </div>
                <div>
                  Product Catalogue:&nbsp;{product[0]?.product_catalogue}{" "}
                </div>
                <div className="d-flex">
                  <a
                    href="#demo-modal"
                    className="btn btn-black btn-purple  text-white"
                  >
                    View Project
                    <img src={whiterightarrow}  alt ="" />
                  </a>
                </div>
              </div>
              <div className="image-section">
                <img src={`https://smarto.s3.ap-south-1.amazonaws.com/mindcode/product/${product[0]?.product_img}`}  alt ="" />
              </div>
            </div>
            <div id="demo-modal" className="modal">
              <div className="modal__content">
                <h2 className="text-purple">Get A Free Demo</h2>
                <p className="fw-medium">
                  Embrace Opportunity: A Pathway to Success
                </p>
                <div className="form-container">
                  <div className="form-group">
                    <label htmlFor="product">Select Product:</label>
                    <select id="product" name="product">
                      <option value="product1">Comercio</option>
                      <option value="product2">Product 2</option>
                      <option value="product3">Product 3</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                      type="text"
                      id="name"
                      placeholder="MCL"
                      name="name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      placeholder="info@gmail.com"
                      id="email"
                      name="email"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Phone:</label>
                    <input
                      type="tel"
                      placeholder="+91 9896570012"
                      id="phone"
                      name="phone"
                      required
                    />
                  </div>
                  <div className="form-group full-width">
                    <label htmlFor="message">Message:</label>
                    <textarea
                      id="message"
                      name="message"
                      placeholder="Enter your message here"
                      required
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group full-width button">
                    <button className="btn btn-black" type="submit">
                      Get a demo
                    </button>
                  </div>
                </div>
                <a href="#" className="modal__close">
                  <img src={closeicon}  alt ="" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className id="Comercio-discription">
          <div className="container ">
            <div className="card-container bg-white text-gray">
              <div>
                <span className="fw-semi-bold"> Payment Processing:&nbsp;</span>
                {product[0]?.payment_processing}
              </div>
              <div>
                <span className="fw-semi-bold">Shipping and Delivery: </span>
                {product[0]?.shipping_delivery}
              </div>
              <div>
                <span className="fw-semi-bold">Customer Service:&nbsp;</span>
                {product[0]?.customer_service}
              </div>
        
            </div>
            <div className="card-container bg-white text-gray">
              <div>
                <span className="fw-semi-bold">
                  Marketing and Advertising:&nbsp;
                </span>
                {product[0]?.marketing_advertising}
              </div>
              <div>
                <span className="fw-semi-bold">
                  Analytics and Insights:&nbsp;
                </span>
                &nbsp;{product[0]?.analytics_insights}
              </div>
              <div>
                <span className="fw-semi-bold">
                  E-commerce Technology:&nbsp;&nbsp;
                </span>
                &nbsp;{product[0]?.Ecomm}
              </div>
            </div>
          </div>
        </section>
        <Contactus />
      </div>
    </>
  );
}

export default Comercio_content;
