import React from "react";

const MainBanner = (props) => {
  return (
    <div>
      <section id="main-banner-about">
        <div id="about-page" className="banner-section-inner container">
          <div className="about-sec-grid text-color-white">
            <div>
              <h1 className="text-white">{props.page}</h1>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainBanner;
