import React from 'react'
import unsplash_FBX_qonbn5M from '../../assets/images/unsplash_FBX_qonbn5M.png'
import unsplash_g1Kr4Ozfoac from '../../assets/images/unsplash_g1Kr4Ozfoac.png'
import ourwork from '../../assets/images/ourwork.png'
import stratagy from '../../assets/images/stratagy.png'
import Contactus from '../Common/Contactus'
import MainBanner from './MainBanner'


function About_content() {
    return (
        <>
            <MainBanner page="ABOUT" />
            <section className="section2 aboutsection2" id="company-history">
                <div className="our-service-section container">
                    <div className="bg-white" >
                        <div className="grid-row">
                            <div className="left-side grid-coloumn">
                                <div className="header-text">
                                    <h2 className="text-purple">Company History</h2>
                                    <p className="text-black fw-medium">
                                        Back in 2013, a small dream took shape in an ambitious mind.
                                        Today, that dream has blossomed into the thriving business known
                                        as MindCodeSolutions.
                                    </p>
                                    <div className="text-gray  ">
                                        Over the last decade, our company has experienced incredible
                                        growth thanks to our expertise, dedication, and commitment to
                                        serving people with loyalty and quality
                                    </div>
                                    <div className="text-gray">
                                        Along this journey of transformation, we've had the privilege to
                                        serve esteemed clients like (mention names of clients). Guided
                                        by our belief in unity and reliability, we've embraced the
                                        principle of mutual success, celebrating milestones hand in hand
                                        with our clients.
                                    </div>
                                    <div className="text-gray">
                                        Overall, MindCodeLab is a company that prioritizes excellence,
                                        professionalism, and care in all its endeavours. The company's
                                        commitment to its clients and its core values is evident in its
                                        phenomenal growth and continued success.
                                    </div>

                                </div>
                            </div>
                            <div className="right-side grid-coloumn2">
                                <img src={unsplash_FBX_qonbn5M} alt="" />

                            </div>
                        </div></div>
                </div>
            </section>
            <section className="section3 missionsection2" id="our-mission">
                <div className="our-mission-section container">
                    <div className="grid-row">
                        <div className="card-container grid-row-inner">
                            <div className="text-section">
                                <p className="fw-medium">Your satisfaction, Our Mission</p>
                                <div className="text-gray">
                                    When it comes to providing effective, specialized, and
                                    all-encompassing digital transformation services, Mindcodelab
                                    stands out as a market leader in a competitive landscape. With
                                    everything we do, our mission is to go beyond the client’s
                                    expectations.
                                </div>
                            </div>
                            <div className="image-section1">
                                <img src={unsplash_g1Kr4Ozfoac} alt="" />
                            </div>
                        </div>
                        <div className="card-container grid-row-inner">
                            <div className="text-section">
                                <p className="fw-medium">Our work sparks our excellence</p>
                                <div className="text-gray">
                                    Over the years, MindCodeLab has achieved significant milestones
                                    and garnered recognition for our outstanding work in the
                                    industry. Our commitment to excellence and client satisfaction
                                    has earned us a reputation as a trusted partner for businesses
                                    looking to thrive in the digital realm.
                                </div>
                            </div>  <div className="image-section1">
                                <img src={ourwork} alt="" />
                            </div>

                        </div>
                        <div className="card-container card-container-last grid-row-inner">
                            <div className="text-section">
                                <p className="fw-medium">Unity is the essence of great things.</p>
                                <div className="text-gray">
                                    We believe in the famous quote by Henry Ford ‘’Coming together
                                    is a beginning, staying together is progress, and working
                                    together is success ‘’.Our team at MindCodeLab is a diverse
                                    group of skilled professionals dedicated to pushing boundaries
                                    and exceeding expectations in every project while working in a
                                    collaborative and friendly environment. With a wealth of
                                    experience in design, development, marketing, and branding, our
                                    team members are passionate about delivering top-notch solutions
                                    tailored to each client's unique needs.
                                </div>
                            </div>
                            <div className="image-section1">
                                <img src={unsplash_g1Kr4Ozfoac} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section2 strategy-section" id="our-strategy">
                <div className="our-quality-section container">
                    <h2 className="text-white">We Provide strategy and vision to our Leader</h2>
                    <div className="inner-strategy">
                        <div className="right-side">
                            <img src={stratagy} alt="" />
                        </div>
                        <div className="left-side">
                            <div className="card-container grid-row-inner">
                                <div className="text-section">
                                    <p className="fw-medium">Enthusiastic About Your Achievement</p>
                                    <div className="text-gray">
                                        In all we do, we think globally. We are responsible citizens
                                        who are dedicated to improving the world through our
                                        innovative and cutting-edge technological applications.
                                    </div>
                                </div>
                            </div>
                            <div className="card-container grid-row-inner">
                                <div className="text-section">
                                    <p className="fw-medium">A Shared Sense Of Respect</p>
                                    <div className="text-gray">
                                        We support a culture of learning, improvement, and sharing. We welcome the variety of ideas and individuals in our culture, which is varied.
                                    </div>
                                </div>
                            </div>
                            <div className="card-container grid-row-inner">
                                <div className="text-section">
                                    <p className="fw-medium">Never compromise your morality</p>
                                    <div className="text-gray">
                                        Our fundamental principle, which serves as the foundation for all we stand for, is replete with integrity. Beyond simply abiding by the law, we strive to surpass your expectations by keeping our promises.
                                    </div>
                                </div>
                            </div>
                            <div className="card-container grid-row-inner">
                                <div className="text-section">
                                    <p className="fw-medium">Enthusiastic About Your Achievement</p>
                                    <div className="text-gray">
                                        In all we do, we think globally. We are responsible citizens
                                        who are dedicated to improving the world through our
                                        innovative and cutting-edge technological applications.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="client-point">
                <div className="container client-point-inner">
                    <div className="d-flex bg-white">

                        <h2 className="fw-semi-bold">Client point users have experienced...</h2>

                        <div className="black-box-container">
                            <div className="grid-item">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <h2>30%</h2>
                                        <div className="mt-4">higher close rates</div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-item">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <h2>90%</h2>
                                        <div className="mt-4">eliminating cost</div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-item">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <h2>100%</h2>
                                        <div className="mt-4">increase in sale</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           <Contactus/>
           

        </>
    )
}

export default About_content