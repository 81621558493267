import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./component/pages/About";
import Home from "./component/pages/Home";
import Product from "./component/pages/Product";
import Service from "./component/pages/Service";
import Career from "./component/pages/Career";
import Comercio from "./component/pages/Comercio";
import Contact from "./component/pages/Contact";
import Event from "./component/pages/Event";
import Intern from "./component/pages/Intern";
import Personal from "./component/page_content/Intern_form/Personal_info";



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/product" element={<Product />} />
          <Route path="/service" element={<Service />} />
          <Route path="/career" element={<Career />} />
          <Route path="/comercio/:id" element={<Comercio />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/event" element={<Event />} />
          <Route path="/internship" element={<Intern />} />
          <Route path="/personal_info" element={<Personal />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
