import React from 'react'
import Header from '../Common/Header'
import CareerContent from '../page_content/Career_content'
import Footer from '../Common/Footer'

function Career() {
  return (
    <div>
      <title>Career </title>

      <Header />
      <CareerContent />
      <Footer />
    </div>
  )
}

export default Career