import React, { useEffect, useState } from "react";
import Contactus from "../Common/Contactus";
import uploadicon from "../../assets/images/uploadicon.png";
import closeicon from "../../assets/images/closeicon.svg";
import redefineculture from "../../assets/images/redefineculture.svg";
import PioneeringInnovativeThinking from "../../assets/images/Pioneering-Innovative-Thinking.svg";
import NurturingGrowth from "../../assets/images/Nurturing-Growth.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Career_content() {
  const Success_notify = () => toast(`Application Submitted Successfully`);
  const error_notify = () => toast("Please Try Again Later");
  const [career, setCareer] = useState([]);
  const [form, setForm] = useState({});

  const handleinput = (e) => {
    const { name, value, files } = e.target;
    if (name === "Cv_img") {
      setForm({ ...form, [name]: files[0] });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }
    axios
      .post(
        `https://mindcoadmin.v4masters.com/public/index.php/careerapply`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log("server", response.data);
        if (response.data == "success") {
          Success_notify();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
        error_notify();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  const getCareer = () => {
    axios
      .get(`https://mindcoadmin.v4masters.com/public/index.php/getcareer/`)
      .then((response) => {
        console.log(response);
        setCareer(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCareer();
  }, []);

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <section id="main-banner-about">
          <div id="about-page" className="banner-section-inner container">
            <div className="about-sec-grid text-color-white">
              <div>
                <h1 className="text-white">CAREER</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="our-service" id="our-service">
          <div className="our-service-section career  container">
            <div className="bg-white">
              <div>
                <div className="top-grid">
                  <div>
                    <h2 className="text-purple">At Mindcodelab</h2>
                    <p className="fw-medium">
                      Crafting a Dynamic Culture at MindCodeLab
                    </p>
                    <div className="text-gray">
                      We offer impressive mobile app services to accelerate your
                      business to digital world on every device and platform.
                    </div>
                  </div>
                  <div className="right">
                    <div className="fw-medium">Want to join our team ?</div>
                    <div className="d-flex btn-container">
                      <a
                        href="#demo-modal"
                        className="btn btn-black text-white"
                      >
                        Apply Now
                      </a>
                    </div>
                    <div id="demo-modal" className="modal">
                      <div className="modal__content">
                        <h2 className="text-purple">Apply Now</h2>
                        <p className="fw-medium">
                          Embrace Opportunity: A Pathway to Success
                        </p>
                        <form
                          className="form-container"
                          onSubmit={handlesubmit}
                        >
                          <div className="form-group">
                            <label htmlFor="product">
                              Position Applied For:
                            </label>
                            <select
                              id="product"
                              name="product"
                              onClick={handleinput}
                            >
                              <option selected hidden disabled>
                                Select Career Option
                              </option>
                              {career.map((item, index) => (
                                <option value={item.career_name} key={index}>
                                  {item.career_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input
                              type="text"
                              id="name"
                              placeholder="MCL"
                              name="name"
                              required
                              onChange={handleinput}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input
                              type="email"
                              placeholder="info@gmail.com"
                              id="email"
                              name="email"
                              required
                              onChange={handleinput}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="phone">Phone:</label>
                            <input
                              type="tel"
                              placeholder="+91 9896570012"
                              id="phone"
                              name="phone"
                              required
                              onChange={handleinput}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="profile">Linked-in Profile:</label>
                            <input
                              type="text"
                              placeholder="www.linkedin.com/mcl"
                              id="profile"
                              name="profile_url"
                              required
                              onChange={handleinput}
                            />
                          </div>
                          <div className="form-group upload-btn-wrapper ">
                            <label htmlFor="upload">Upload CV:</label>
                            <button className="uploadbtn">
                              <div>CV.docx</div> <img src={uploadicon} alt />
                              <input
                                type="file"
                                placeholder="CV.docx"
                                id="upload"
                                name="Cv_img"
                                required
                                onChange={handleinput}
                              />
                            </button>
                          </div>
                          <div className="form-group full-width button">
                            <button className="btn btn-black" type="submit">
                              Submit Application
                            </button>
                          </div>
                        </form>
                        <a href="#" className="modal__close">
                          <img src={closeicon} alt />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="card-container">
                    <div className="bg-purple">
                      <img src={redefineculture} alt />
                    </div>
                    <p className="text-purple">Redefining Culture</p>
                    <div className="text-gray">
                      Dive into a realm where tradition meets innovation, and
                      norms are transformed into new possibilities. Experience
                      the pulse of change as we redefine culture at MindCodeLab.
                    </div>
                  </div>
                  <div className="card-container">
                    <div className="bg-purple">
                      <img src={PioneeringInnovativeThinking} alt />
                    </div>
                    <p className="text-purple">
                      Pioneering Innovative Thinking
                    </p>
                    <div className="text-gray">
                      Embark on a journey of boundless creativity and
                      groundbreaking ideas within the dynamic landscape of
                      MindCodeLab.
                    </div>
                  </div>
                  <div className="card-container">
                    <div className="bg-purple">
                      <img src={NurturingGrowth} alt />
                    </div>
                    <p className="text-purple">Nurturing Growth</p>
                    <div className="text-gray">
                      Step into a dynamic learning environment at MindCodeLab,
                      where growth is inevitable. Explore a rich tapestry of
                      opportunities, where curiosity thrives, skills are honed,
                      and expertise is cultivated with care.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="current-opportunity" id="current-opportunity">
          <div className="container">
            <div className="bg-white">
              <h2 className="text-purple">Current Opportunities</h2>
              <p className="fw-medium">
                Embrace Opportunity: A Pathway to Success
              </p>
              <div className="opportunity-grid">
                {career.map((items) => (
                  <div className="card-container d-flex">
                    <div>
                      <p className="text-purple">{items.career_name}</p>
                      <div> Positions : {items.position}</div>
                    </div>
                    <div className="d-flex">
                      <a
                        href={`#demo_modal${items.id}`}
                        className="btn btn-black text-white"
                      >
                  
                        Apply Now
                      </a>
                    </div>

                    <div id={`demo_modal${items.id}`} className="modal">
                      <div className="modal__content">
                        <h2 className="text-purple">Apply Now</h2>
                        <p className="fw-medium">
                          Embrace Opportunity: A Pathway to Success
                        </p>
                        <form
                          className="form-container"
                          onSubmit={handlesubmit}
                        >
                          <div className="form-group">
                            <label htmlFor="product">
                              Position Applied For:
                            </label>
                            <select
                              id="product"
                              name="product"
                              onClick={handleinput}
                            >
                             
                              <option value={items.career_name} selected>
                                {items.career_name}
                              </option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input
                              type="text"
                              id="name"
                              placeholder="MCL"
                              name="name"
                              required
                              onChange={handleinput}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input
                              type="email"
                              placeholder="info@gmail.com"
                              id="email"
                              name="email"
                              required
                              onChange={handleinput}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="phone">Phone:</label>
                            <input
                              type="tel"
                              placeholder="+91 9896570012"
                              id="phone"
                              name="phone"
                              required
                              onChange={handleinput}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="profile">Linked-in Profile:</label>
                            <input
                              type="text"
                              placeholder="www.linkedin.com/mcl"
                              id="profile"
                              name="profile_url"
                              required
                              onChange={handleinput}
                            />
                          </div>
                          <div className="form-group upload-btn-wrapper ">
                            <label htmlFor="upload">Upload CV:</label>
                            <button className="uploadbtn">
                              <div>CV.docx</div> <img src={uploadicon} alt />
                            </button>
                            <input
                              type="file"
                              placeholder="CV.docx"
                              id="upload"
                              name="Cv_img"
                              required
                              onChange={handleinput}
                            />
                          </div>
                          <div className="form-group full-width button">
                            <button className="btn btn-black" type="submit">
                              Submit Application
                            </button>
                          </div>
                        </form>
                        <a href="#" className="modal__close">
                          <img src={closeicon} alt />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <Contactus />
      </div>
    </>
  );
}

export default Career_content;
