import React from 'react'
import Header from '../Common/Header'
import EventContent from '../page_content/Event_content'
import Footer from '../Common/Footer'

function Event() {
  return (
    <div>
      <title>Events </title>

      <Header />
      <EventContent />
      <Footer />
    </div>
  )
}

export default Event