import React from 'react'
import Header from '../Common/Header'
import AboutContent from '../page_content/About_content'
import Footer from '../Common/Footer'

function About() {
  return (
    <div>
      <title>About </title>
        <Header/>
        <AboutContent/>
        <Footer/>
    </div>
  )
}

export default About