import React, { useState } from 'react'
import email from '../../assets/images/email.svg'
import phone from '../../assets/images/phone.svg'
import location from '../../assets/images/location.svg'
import buisness from '../../assets/images/buisness.svg'
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { json } from 'react-router-dom'



function Contact_content() {
  const [form, setFrom] = useState({})
  const [service, setservice] = useState([])
  const Success_notify = () => toast("Enquiry Submitted Successfully");
  const error_notify = () => toast("Please Try Again Later");


  const handleservice = (e) => {
    const newService = e.target.value;
    setservice((prevService) => {
      const updatedService = prevService ? `${prevService}, ${newService}` : newService;
      setFrom((prevForm) => ({
        ...prevForm,
        services: updatedService
      }));
      return updatedService;
    });
  }

  const handleInput = (e) => {
    console.log(e.target.value)
    setFrom({
      ...form,
      [e.target.name]: e.target.value,
    })
  }
  let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    axios.post('https://mindcoadmin.v4masters.com/public/index.php/contactus', form)
      .then((response) => {
        if (response.data == "success") {
          Success_notify()
          setTimeout(()=>{
            window.location.reload()
          },3000)
        } else {
          error_notify()
        }
      })
      .catch((error) => {

      })
    }
    return (
      <>
        <section className="contact-us" id="rounded-box-lg">
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <div className="container">
            <div className="bg-white">
              <div className="right">
                <h2 className="text-purple">Let’s level up your brand, together</h2>
                <p className="fw-medium">you can reach us anytime via <span className="text-purple">
                  sales@mindcodelab.pro</span></p>
                <form onSubmit={handleSubmit}>
                  <div className="form-container">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input type="text" id="name" placeholder="Your Name" name="fname" required onChange={handleInput} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input type="email" placeholder="info@gmail.com" id="email" name="email" required onChange={handleInput} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Phone Number</label>
                      <input type="tel" placeholder="+91 9896570012" id="phone" name="phone" required onChange={handleInput} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="help">How can we help you?</label>
                      <textarea id="help" name="message" placeholder="Tell us little more about project.." required defaultValue={""} onChange={handleInput} />
                    </div>
                    <p>Services</p>
                    <div className="checkboxes">
                      <div className="checkbox-label">
                        <input type="checkbox" id=" Website-Design" name="services" className="check-size" defaultValue=" Website-Design" onClick={handleservice}/>
                        <label htmlFor=" Website-Design"> Website Design</label>
                      </div>
                      <div className="checkbox-label">
                        <input type="checkbox" id="Website-Development" name="services" className="check-size" defaultValue="Website-Development" onClick={handleservice}/>
                        <label htmlFor="Website-Development">Website Development</label>
                      </div>
                      <div className="checkbox-label">
                        <input type="checkbox" id="App-Design" name="services" className="check-size" defaultValue="App-Design" onClick={handleservice}/>
                        <label htmlFor="App-Design">App Design &amp; Development</label>
                      </div>
                      <div className="checkbox-label">
                        <input type="checkbox" id="Digital-Marketing" name="services" className="check-size" defaultValue="Digital-Marketing" onClick={handleservice}/>
                        <label htmlFor="Digital-Marketing">Digital Marketing</label>
                      </div>
                      <div className="checkbox-label">
                        <input type="checkbox" id="It-Support" name="services" className="check-size" defaultValue="It-Support" onClick={handleservice}/>
                        <label htmlFor="It-Support">It Support</label>
                      </div>
                      <div className="checkbox-label">
                        <input type="checkbox" id="Cloud-Services" name="services" className="check-size" defaultValue="Cloud-Services" onClick={handleservice}/>
                        <label htmlFor="Cloud-Services">Cloud Services</label>
                      </div>
                    </div>
                    <div className="form-group full-width button">
                      <button className="btn btn-black text-white fw-medium" type="submit">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="left-side sec2 grid-coloumn">
                <div className="header-text">
                  <h2 className="text-purple">We'd love to hear from you</h2>
                  <p>We are one call away...</p>
                  <div className="text-gray">
                    Give your Idea a digital escalation Get started with MindCodeLab today
                  </div>
                </div>
                <ul className="main-list">
                  <li>
                    <a href="mailto:sales@mindcondelab.pro" className="d-flex">
                      <img src={email} alt="" />
                      <div>
                        <span className="text-gray">Email Address </span> <br />
                        <span className="text-black">sales@mindcondelab.pro</span>
                      </div>
                    </a>
                  </li>
                  <li className="details-list">
                    <a href="tel:+91 9896570012" className="d-flex">
                      <img src={phone} alt="" />
                      <div>
                        <span className="text-gray">Phone Numbers </span> <br />
                        <span className="text-black">+1(343)453-0013&nbsp;|&nbsp;+91 9896570012</span>
                      </div>
                    </a>
                  </li>
                  <li className="details-list">
                    <a href="#" className="d-flex">
                      <img src={location} alt="" />
                      <div>
                        <span className="text-gray">Office Location</span> <br />
                        <span className="text-black">1315 Kingston Rd, Toronto, (Canada)</span><br />
                        <span className="text-black">
                          SCO 12-13 , Sec 65, Chandigarh(India)</span>
                      </div>
                    </a>
                  </li>
                  <li className="details-list">
                    <a href="#" className="d-flex">
                      <img src={buisness} height="max-content" alt="" />
                      <div>
                        <span className="text-gray">Buisness Hours</span> <br />
                        <span className="text-black"> Monday to Saturday </span><br />
                        <span className="text-black"> 09:00 AM to 06:00 PM</span>
                      </div>
                    </a>
                  </li>
                </ul>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.485928423551!2d76.74569147548087!3d30.676601288343385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fec09298bee71%3A0x45b52eb161d8fe49!2sPJC%20Infotech!5e0!3m2!1sen!2sin!4v1720704576593!5m2!1sen!2sin" width="100%" height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
              </div>
            </div>
          </div>
        </section>

      </>
    )
  }

  export default Contact_content